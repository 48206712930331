@import "main.css";
h1, h2, h3, h4 {
text-transform: uppercase;
}

a.text-color-fourth {
text-transform: uppercase;
font-weight: normal !important;
color: white !important;
}

.websitePageTitle-div {
display : none !important;
}

div.image-video-logo {
    width: 30% !important;
}

/* ----------------------------------------------------------------------------------------------Ajustements SEO */

.bandeau h1 {
    color: black !important;
    font-weight: normal !important;
}

div.px-5.pt-5.display-6.text-color-primary.titles {
font-weight: normal !important;
}

.seo-frequently-card span {
font-weight: normal !important;
}

.seo-frequently-card li {
font-weight: normal !important;
}

#custom-cards h2 {
font-weight: normal !important;
}

#custom-cards h3 {
font-weight: normal !important;
}

#custom-cards li {
font-weight: normal !important;
}

.lead p {
font-weight: normal !important;
}


/* --------------------------------------------------Tableaux --------------------------------------------*/

table {
width: 100%;
    max-width: 100%;
    overflow-x: auto;
border: none;
display: block;
}

tbody {
background-color:white;
}

/* --------------------------------------------------Barre de navigation---------------------------------------------*/

.navbar-brand {
    width: 10vw !important;
}

.navbar {
    backdrop-filter: blur(2px);
}

.animated-border-button {
background-color: transparent !important;
}

/* --------------------------------------------------Page contact----------------------------------------------*/

#contact .text-body-emphasis {
    height: 500px;
    background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2Fpage_contact_4n65.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* --------------------------------------------------Bloc divider ----------------------------------------------*/

.b-bloc-divider {
    height: 60px !important;
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
}


/*------------------------------------------------------Bloc avis Google---------------------------------------------*/

.owl-carousel .owl-stage-outer {
    overflow: initial !important;
}

/*------------------------------------------------------Bannière vidéo---------------------------------------------*/

.video-background-holder h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.videoTitle {
    width: 80% !important;
}


/*------------------------------------------------------Bloc Maps---------------------------------------------*/


body > main > div.wrapper > div > div.maps-section.position-relative.bg-primaryColor > div.content-container.position-relative > div > div.col-sm-12.col-lg-6.col-xl-4.py-5.content.text-color-primary {
color: white !important;
}


/*------------------------------------------------------CTA Banner---------------------------------------------*/

.cta_banner h2 {
margin-bottom: 3rem !important;
}


/*------------------------------------------------------Bloc insta---------------------------------------------*/

.instagramMiroir-div {
    background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2FBloc_insta_xf0x.webp);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 50px 0;
}

/*------------------------------------------------------Images d'arrière plan texte + image ---------------------------------------------*/

/*-------------------------Page d'accueil --------------------------*/

#livraison + div.mx-auto {
    background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2Fparagraphe_livraison_v9g8.webp);
    background-size: cover;
    background-position: center;
background-attachment: fixed;
}

#seminaire + div.mx-auto {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2F3eme_paragraphe_xyhg.webp);
    background-size: cover;
    background-position: center;
background-attachment: fixed;
}

#evenement + div.mx-auto {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2F2eme_paragraphe_xbk8.webp);
    background-size: cover;
    background-position: center;
background-attachment: fixed;
}


#emporter + div.mx-auto {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2Fparagraphe_takeaway_xxmf.webp);
background-size: cover;
    background-position: center;
background-attachment: fixed;
}

#horaires + div.mx-auto {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2Fparagraphe_horaires_f4ox.webp);
background-size: cover;
    background-position: center;
background-attachment: fixed;
}

#brunch + div.mx-auto {
background-image: url(https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FQffRozOr2jRUdXtP2N8oJvnKQNf1%2Fimages%2Fparagraphe_brunch_1_uvz8.webp);
background-size: cover;
    background-position: center;
background-attachment: fixed;
}
